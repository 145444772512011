<template>
  <div class="page-layout">
    
    <HeaderIntroImage underlined :headerMetadata="pageData"></HeaderIntroImage>

    <div v-for="(team, index) in teamsData" :key="'team'+index" :class="[index%2==0 ? '' : 'fullwidth gray-bg']">
      <div class="container">
        <div class="coordination-team">

          <!-- <span> {{ team.attributes.field_coord_team_org_website.uri }}</span> -->

          <div class="intro">

            <p class="label blue tag">{{ team.attributes.field_coord_team_org + ' - ' + team.attributes.field_coord_team_wp_name }}</p>
            <h2 class="gray title">{{ team.attributes.title }}</h2>

            <a :href="team.attributes.field_coord_team_org_website.uri" target="_blank" class="logo">
              <picture>
                <img :src="logoimg(index)" alt="Consiglio Nazionale delle Ricerche">
              </picture>
            </a>

          </div>
          
          <ArticlesGrid class="member--grid">
            <MemberCard groupleader v-if="team.field_coord_team_leader"
              :memberData="findData(team.field_coord_team_leader.attributes.drupal_internal__nid)" 
              :uid="findData(team.field_coord_team_leader.attributes.drupal_internal__nid).uid"
              :routesData="routesData" />
            <div v-for="(member, i) in team.field_coord_team_member" :key="'member'+i" >
              <MemberCard v-if="member"
                :uid="findData(member.attributes.drupal_internal__nid).uid"
                :memberData="findData(member.attributes.drupal_internal__nid)" :routesData="routesData" />
            </div>
          </ArticlesGrid>

          <div class="goals">
            <p class="teaser" ref="team_description" v-html="team.attributes.field_coord_team_goal.value"></p>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
import ArticlesGrid from '../components/layout-blocks/ArticlesGrid.vue'
import MemberCard from '../components/layout-blocks/MemberCard.vue'
import { fetchNodes } from '../libs/drupalClient'
export default {
  name: 'coordination-team',
  components: {
    HeaderIntroImage,
    ArticlesGrid,
    MemberCard
  },
  props: {},
  data: () => {
    return {
      pageData: {
        attributes: {
          title: "Coordination Team",
          tag: "",
          subtitle: ""
        }
      },
      currentPageID: "21b67bf4-8879-4021-88cc-4550012e4c0f",
      teamsData: [],
      membersData: [],
      leaderData: []
    }
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    },
    routesData() {
      return this.$store.state.routesData
    }
  },
  methods: {
    logoimg(t) {
      return process.env.VUE_APP_ENDPOINT + this.teamsData[t].field_coord_team_org_logo.attributes.uri.url
    },
    findData(nid) {
      return this.membersData.find(m => m.attributes.drupal_internal__nid == nid)
    },
    orderMembers(array) {
      let first = array.filter(m => m.attributes.field_coordinator_type=='1')
      .sort((a,b) => { return a.attributes.field_last_name.localeCompare(b.attributes.field_last_name) })
      //.sort((a,b) => { return a.attributes.field_last_name > b.attributes.field_last_name ? 1 : -1 })

      let second = array.filter(m => m.attributes.field_coordinator_type=='2')
      .sort((a,b) => { return a.attributes.field_last_name.localeCompare(b.attributes.field_last_name) })
      //.sort((a,b) => { return a.attributes.field_last_name > b.attributes.field_last_name ? 1 : -1 })

      let third = array.filter(m => m.attributes.field_coordinator_type==null)
      .sort((a,b) => { return a.attributes.field_last_name.localeCompare(b.attributes.field_last_name) })
      //.sort((a,b) => { return a.attributes.field_last_name > b.attributes.field_last_name ? 1 : -1 })

      return first.concat(second).concat(third)
    }
  },
  async mounted() {
    await fetchNodes('coordination_team', {
      include: ['field_coord_team_member', 'field_coord_team_leader', 'field_coord_team_org_logo'],
      nested_include: [
        'field_coord_team_member.field_organization', 'field_coord_team_member.field_photo', 'field_coord_team_member.uid',
        'field_coord_team_leader.field_photo', 'field_coord_team_leader.field_organization', 'field_coord_team_leader.uid'
      ]
    })
    .then(res => {
      //console.log(res)
      this.teamsData = res

      this.teamsData.forEach(t => {
        t.field_coord_team_member = this.orderMembers(t.field_coord_team_member.filter(m => m!=null))

        if(t.field_coord_team_leader)
          this.membersData.push(t.field_coord_team_leader)

        t.field_coord_team_member.forEach(p => {
          if(p)
            this.membersData.push(p)
        })
      })

      this.membersData = this.orderMembers(this.membersData)
      
      //console.log(this.membersData)
    })

  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";

.teaser {
  transition: all 300ms ease;
  ::v-deep p { margin: 0; }
  &.hide { 
    height: 0px;
    overflow: hidden;
    transition: all 300ms ease;
  }
}

.coordination-team {
  position: relative;
  margin: 1em 0 2em 0;
  border-bottom: 2px darken($hoverGray, 10%) dashed;

  .goals {
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    min-height: 4em;
    position: relative;

    button {
      position: absolute;
      bottom: 0;
      right: 0;
      height: fit-content;
      width: fit-content;
      background: linear-gradient(#fff, #fff) border-box;
      &:hover {
        background: $gradient border-box;
      }
    }
  }

  .intro {
    height: fit-content;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .tag {
    font-size: 14px;
    margin: 0;
  }
  .title {
    margin: 0;
  }

  .logo {
    display: block;
    position: relative;
    width: fit-content;
    height: 80px;
    max-width:150px;
    margin: 1rem 0;
    picture img {
      object-fit: contain;
    }
  }
}

.gray-bg {
  .coordination-team .goals button {
    background: 
      linear-gradient($lightGray, $lightGray) border-box,
      linear-gradient($lightGray, $lightGray) padding-box;
    &:hover {
      background: 
        $gradient border-box,
        $gradient padding-box;
    }
  } 
}

@media (min-width: 768px) {
  .coordination-team {
    .intro {
      height: 100px;
      .title { width: 90%; }
      margin-bottom: 1.5em;
    }
    .logo {
      position: absolute;
      top: 0;
      right: 0;
      max-width: 120px;
      height: 100%;
      margin-top: 0;
    }
    .teaser {
      width: 60%;
    }
  }
}

</style>