<template>
    <article :class="['member', leaderlabels.length>0 ? 'leader': '']" 
        v-if="memberData && memberData.attributes && isVisible">

        <router-link :to="personlink" 
            :class="{
                'tile': true, 
                'plain': true,
                'private-profile': !isClickable
            }" >

            <div class="article--thumbnail">
                <ImageItem v-if="memberData.field_photo" :imagedata="memberData" 
                    fieldname="field_photo" 
                    :breakstyles="{'0': 'xs_image','576': 'sm_image'}">
                </ImageItem>
                <picture v-else>
                    <img src="../../assets/profile.jpg" alt="profile picture placeholder">
                </picture>
            </div>

            <div class="article--content">
                <div class="article--tag blue">
                    <div v-for="(l, ind) in this.leaderlabels" :key="'leader'+ind">{{ l }}</div>
                </div>

                <h4 class="article--title">
                    {{ memberData.attributes.title }}
                </h4>
                
                <div class="article--teaser teaser">
                    <p>{{ organization.field_org_position }}</p>
                </div>

                <div class="article--meta">
                    <span class="label" v-if="organization.field_country">{{
                        this.$store.getters.getCountry(organization.field_country.country)     
                    }}</span>
                    <div class="contacts">
                        <p v-for="(phone, index) in memberData.attributes.field_phone" :key="'phone'+index" class="tel icon-left">
                            {{ phone }}
                        </p>
                        <!-- <p v-for="(email, index) in memberData.attributes.field_email" :key="'mail'+index" class="email icon-left">
                            <a class="email dark" :href="'mailto:'+email">{{ email }}</a>
                        </p> -->
                        <p class="email icon-left">
                            <a :href="'mailto:' + uid.attributes.display_name" class="email dark">{{ uid.attributes.display_name }}</a>
                        </p>
                    </div>
                </div>
            </div>
            
        </router-link>
    </article>
</template>

<script>
import ImageItem from '../atomic-elements/ImageItem'
export default {
    name: 'member-card',
    components: {
        ImageItem
    },
    props: {
        groupleader: Boolean,
        wgcoordinator: Boolean,
        assistant: Boolean,
        coordinator: Boolean,
        memberData: Object,
        uid: Object,
        routesData: Array,
        imageData: Object,
        backimg: Object,
        fieldname: {        
            default: 'field_banner_top',    
            type: String                    
        }
    },
    data: () => {
        return {}
    },
    /* mounted() {
       //console.log(this.uid.attributes.display_name)
    }, */
    computed: {
        loggedUser() {
            return this.$store.getters.getLogged
        },
        organization() {
            return this.memberData.field_organization[0].attributes
        },
        profilepic() {
            return process.env.VUE_APP_ENDPOINT + this.memberData.field_photo.attributes.uri.url
        },
        leaderlabels() {
            var ll = []
            
            if(this.groupleader)
                ll.push("GROUP LEADER")
            if(this.coordinator)
                ll.push("PROJECT COORDINATOR")
            if(this.assistant) 
                ll.push("WORKING GROUP ASSISTANT")
            if(this.wgcoordinator)
                ll.push("WORKING GROUP COORDINATOR")
            
            if(this.memberData) {
                if(this.memberData.attributes.field_coordinator_type==1)
                    ll.push("QTEdu COORDINATOR")
                else if(this.memberData.attributes.field_coordinator_type==2)
                    ll.push("QTEdu CO-COORDINATOR")
            }

            return ll
        },
        personlink() {
            var path = '/not-found'

            var link = this.routesData.filter(r => r.nid == this.memberData.attributes.drupal_internal__nid)

            if(link.length>0 && this.isClickable)
                path = link[0].path
            
            return path
        },
        isVisible() {
            return this.loggedUser //LOGGED USER CAN SEE EVERYTHING (ALSO NOT CREATED YET --> visibility==null)
                || (!this.loggedUser //NOT LOGGED USER CAN SEE JUST FULL/LIMITED CREATED MEMBERS 
                    && this.memberData.attributes.field_member_data_visibility!='None' 
                    && this.memberData.attributes.field_member_data_visibility)
        },
        isClickable() {
            return this.loggedUser //LOGGED USER CAN ENTER ALL PROFILES
                || (!this.loggedUser //NOT LOGGED CAN ENTER ONLY ON FULL MEMBERS
                    && this.memberData.attributes.field_member_data_visibility=='Full'
                    && this.memberData.attributes.field_member_data_visibility)
        }
    }
}
</script>

<style lang="scss" scoped>
.article--teaser p {
    line-height: 1.2em;
}
</style>
